import React from 'react';
import { LineContext } from 'components/context';
import AppointmentsPage from 'pages/appointments';
import usePages from 'src/customHooks/pages';

export default function Home(props) {
  const { message, token } = usePages();
  if (token) {
    return (
      <LineContext.Provider value={message}>
        <AppointmentsPage {...props} {...message}></AppointmentsPage>
      </LineContext.Provider>
    );
  }
  return null;
}
